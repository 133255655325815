
.name-options-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  @media (max-width: 670px) {
    .name-options-container {
      flex-direction: column; /* Stack buttons vertically */
      gap: 15px; /* Add more space between buttons */
    }
  }

  .custom-outlined-button {
    border: 1px solid #607D8B; /* Replace with your desired color */
  }
