@import '../../../styles/stylesVariables.scss';

.inbox_data_table_scroll_wrap {
  height: calc(
    100vh - #{$mainHeader} - 90px
  ); // Do not change 90px. If you want to change it then check all the places where Daysheet table is used, including mobile view.
}

.file-name-cell {
  white-space: normal !important;
  word-break: break-word;
  overflow-wrap: break-word;
  display: block;
}
