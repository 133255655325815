.p-button-facebook {
  background-color: #3b5998;
  color: white;
}

.p-button-linkedin {
  background-color: #0077b5;
  color: white;
}

.p-button-twitter {
  background-color: #1da1f2;
  color: white;
}

.p-button-telegram {
  background-color: #0088cc;
  color: white;
}

.p-button-email {
  background-color: #6c757d;
  color: white;
}
