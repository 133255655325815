.buttonsSectionsWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
  }
  
  @media (max-width: 920px) {
    .buttonsSectionsWrap {
      justify-content: center;
    }
  }
  