.mrp_referral {
  display: block;
  position: relative;

  .p-inputtext {
    padding-right: 90px;
  }

  svg {
    right: 7rem !important;
  }

  // Add new practitioner button
  #addNewPractitioner {
    position: absolute;
    width: 42px !important;
    right: 42px;
    height: 40px;
    z-index: 1;
  }
}
