@import '../../../../public//assets/sass/overrides/layout_variables';

.successMessage {
  font-size: 1.2em;
  color: $success;
}

.errorMessage {
  font-size: 1.2em;
  color: $danger;
}

.successIcon {
  font-size: 4em;
  color: $success;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

.errorIcon {
  font-size: 4em;
  color: $danger;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
}
