@import '../../../../../public/assets/sass/overrides/layout_variables';


.buttons {
  font-size: 1.2em;
  width: 200px;
  height: 42px;
}

.patientProfileMenu {
  width: auto;
  min-width: 188px;
  max-width: 250px;
}


.successIcon{
  color: $success
}